import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to My Website</h1>
        <p>This is a simple home page built with React and CSS.</p>
      </header>
    </div>
  );
}

export default App;